
import { defineComponent, inject } from "vue";
import ConsentService from "@/services/consentService";

import ApplicationStore from "@/stores/applicationStore";
import Footer from "@/components/Footer.vue";
import Consent from "@/domain/consent";

import Panel from "@/components/Panel.vue";
import PanelGroup from "@/components/PanelGroup.vue";
import ExpandableTextbox from "@/components/ExpandableTextbox.vue";
import ListAccounts from "@/components/ListAccounts.vue";
import useNavigation from "@/helpers/useNavigation";



export default defineComponent({
  components: {
    Panel,
    ExpandableTextbox,
    PanelGroup,
    ListAccounts,
    Footer,
  },
  data() {
    return {
      selectedAccounts: [] as string[],
      errorMessage: "",
    };
  },
  props: {
    token: { type: String },
    partyKey: { type: String },
    consentId: { type: String },
  },
  setup() {
    const consentService = inject("consentService") as ConsentService;
    const applicationStore = inject("applicationStore") as ApplicationStore;

    const navigationHandler = useNavigation();

    const consentRequest = applicationStore.consentRequest as Consent;
    const startDate = new Date(Date.now());

    return {
      navigationHandler,
      consentService,
      applicationStore,
      consentRequest,
      duration: {
        start: startDate,
        end: new Date(
          new Date(Date.now()).setSeconds(
            startDate.getSeconds() +
              consentRequest.consentRequested.claims.sharingDuration
          )
        ),
      },
    };
  },
  methods: {
    arrayEquals(a, b) {
      return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
    },
    authorise(authorise: boolean) {
      this.$data.errorMessage = "";

      try {
        var consentPromise = this.consentService.updateConsent(
          this.applicationStore.accessToken,
          this.applicationStore.partyKey,
          this.$props.consentId as string,
          authorise,
          this.applicationStore.selectedAccounts
        );

        this.applicationStore.updateAuthorisationPromise(consentPromise);

        this.navigationHandler.navigateTo("outcome");
      } catch (e) {
        this.navigationHandler.error("Could not update the consent");
      }
    },
    cancel() {
      this.navigationHandler.cancel();
    },
  },
});
