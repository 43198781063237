
import { defineComponent } from "@vue/runtime-core";
import Logo from "./components/Logo.vue";

export default defineComponent({
  name: "Open Banking App",
  components: {
    Logo,
  },
});
