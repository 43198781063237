import * as Axios from "axios";

import AccountListResponse from "@/domain/accountListResponse";
import { v4 as uuidv4 } from 'uuid';

export default class AccountService {
  constructor(private accountServer, private apiKey) {}

  public async listAccounts(token: string): Promise<AccountListResponse> {
    const apiConfig: Axios.AxiosRequestConfig = {
      baseURL: this.accountServer,
      url: `/v1/subscriptions`,
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Idempotency-Key": uuidv4(),
      },
    };

    try {
      const response = await Axios.default.request<any>(apiConfig);
      return response.data as AccountListResponse;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }
}
